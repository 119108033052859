<template>
	<div>
		<v-sheet
			flat
			v-if="survey"
			color="lightest"
			class="pa-5 mw-survey rounded-lg"
		>
			
			<survey-inner
				:id="id"
				@save="save"
				@setUser="(v) => (participant = v)"
			></survey-inner>
		</v-sheet>
		<v-snackbar v-else color="primary" v-model="error" text>
			No survey found
		</v-snackbar>
	</div>
</template>
<style lang="less"></style>
<script>
import SurveyInner from "@c/survey/render/Survey.vue";
export default {
	name: "Survey",
	props: {
		url: { type: String },
	},
	data: () => {
		return {
			id: null,
			error: false,
			data: {},
			visibleQuestions: [],
			participant: false,
		};
	},
	components: {
		SurveyInner,
	},
	methods: {
		save(response){
			const self = this;
			self.$store
			.dispatch("surveyResponses/newResponse", response )
			.then(() => {
				self.redirect();
			});
		},
		redirect() {
			const self = this;
			if (self.survey.redirect_to_scheduling) {
				self.$router.push({
					name: "Scheduler",
					query: { q: self.participant },
					params: { id: self.survey.redirect_to_scheduling, from: this.id },
				});
			} else if (self.survey.redirect_url) {
				let url = self.survey.redirect_url;
				if (!self.logged_in) {
					url += "?q=";
					url += self.participant;
				}
				window.location = url;
			} else {
				self.$router.replace({
					name: "ThankYou",
					params: { id: self.id },
				});
			}
		},
	},
	computed: {
		logged_in() {
			return this.$store.state.auth.data.id ? true : false;
		},
		survey() {
			return this.$store.state.surveys.data[this.id];
		},
	},
	created() {
		this.$store.dispatch("surveys/getSurveyByUrl", this.url).then((id) => {
			this.id = id;
		});
	},
	provide() {
		return {
			id: this.id,
		};
	},
};
</script>
